<template>
  <div @mouseenter="hoverHead=true" @mouseleave="handleMouseLeave" :class="isWhite && isTop && (hoverVal === '') && (!isShow || !isMobile) ? 'header-main white':'header-main'">
    <a-col class="logo-hd-box" >
      <router-link to="/">
        <i :class="'logo-hd-' + brand.toLowerCase()" :style="logo" />
      </router-link>
      <i :class="!isShow ? 'icon icon-show' : 'icon icon-close'" @click="handleClick()" />
    </a-col>
    <div :class="isShow ? 'right-box active' : 'right-box'">
      <ul v-if="!isMobile" class="navigation-list">
        <li @mouseenter="handlerHover('product')"  >
          <router-link to="acquiring">
            <a href="javascript:;">{{ $t('product') }}</a>
          </router-link>
          <div :class="[hoverVal, 'header-drop-down', hoverVal === 'product' ? 'active': '']">
            <div class="content">
              <div :class="['product-wrapper head-drop-box']">
                <h3 v-if="!isMobile" class="title">{{ $t('product') }}</h3>
                <ul class="list">
                  <li>
                    <router-link to="acquiring">
                      <i class="icon-list icon-list-sd"></i>
                      <div class="text-box">
                        <h4>{{ $t('acquiring') }}</h4>
                        <p>{{ $t('acquiring_desc') }}</p>
                      </div>
                    </router-link>
                  </li>
                  <li>
                    <router-link to="payments">
                      <i class="icon-list icon-list-payment"></i>
                      <div class="text-box">
                        <h4>{{ $t('payment') }}</h4>
                        <p>{{ $t('payment_desc') }}</p>
                      </div>
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </li>
        <li @mouseenter="handlerHover('solution')" >
          <router-link to="solution">
            <a href="javascript:;">{{ $t('solutions') }}</a>
          </router-link>
          <div :class="[hoverVal, 'header-drop-down', hoverVal === 'solution' ? 'active': '']">
            <div class="content">
              <div :class="['solution-wrapper head-drop-box']">
                <h3 v-if="!isMobile" class="title">{{ $t('solutions') }}</h3>
                <ul class="list">
                  <li>
                    <router-link to="solution">
                      <i class="icon-list icon-list-solution"></i>
                      <div class="text-box">
                        <h4>{{ $t('solutions1') }}</h4>
                        <p>{{ $t('solutions_desc') }}</p>
                      </div>
                    </router-link>
                  </li>
                  <li>
                    <router-link to="marketing">
                      <i class="icon-list icon-list-market"></i>
                      <div class="text-box">
                        <h4>{{ $t('market') }}</h4>
                        <p>{{ $t('market_desc') }}</p>
                      </div>
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </li>
        <li>
          <router-link to="about" >
            <a @mouseenter="handlerHover('')" href="javascript:;">{{ $t('about_us') }}</a>
          </router-link>
        </li>
      </ul>
      <ul v-if="isMobile" class="navigation-list">
        <li @mouseenter="handlerHover('product')" @mouseleave="handlerHover('')">
          <a :class="['navigation-item navigation-item-ul', isShowProduct ? 'active': '']" @click="isShowProduct = !isShowProduct" href="javascript:;"><span>{{ $t('product') }}</span><i class="icon icon-right" /></a>
          <div :class="[hoverVal, 'header-drop-down', isShowProduct ? 'active': '']">
            <div class="content">
              <div :class="['product-wrapper head-drop-box']">
                <h3 v-if="!isMobile" class="title">{{ $t('product') }}</h3>
                <ul class="list">
                  <li @click="handleClose">
                    <router-link to="acquiring">
                      <div class="text-box">
                        <h4>{{ $t('acquiring') }}</h4>
                        <p>{{ $t('acquiring_desc') }}</p>
                      </div>
                    </router-link>
                  </li>
                  <li @click="handleClose">
                    <router-link to="payments">
                      <div class="text-box">
                        <h4>{{ $t('payment') }}</h4>
                        <p>{{ $t('payment_desc') }}</p>
                      </div>
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </li>
        <li @mouseenter="handlerHover('solution')" @mouseleave="handlerHover('')" >
          <a :class="['navigation-item navigation-item-ul', isShowSolution ? 'active': '']" @click="isShowSolution = !isShowSolution" href="javascript:;"><span>{{ $t('solutions') }}</span><i class="icon icon-right" /></a>
          <div :class="[hoverVal, 'header-drop-down', isShowSolution ? 'active': '']">
            <div class="content">
              <div :class="['solution-wrapper head-drop-box']">
                <h3 v-if="!isMobile" class="title">{{ $t('solutions') }}</h3>
                <ul class="list">
                  <li @click="handleClose">
                    <router-link to="solution">
                      <div class="text-box">
                        <h4>{{ $t('solutions1') }}</h4>
                        <p>{{ $t('solutions_desc') }}</p>
                      </div>
                    </router-link>
                  </li>
                  <li @click="handleClose">
                    <router-link to="marketing">
                      <div class="text-box">
                        <h4>{{ $t('market') }}</h4>
                        <p>{{ $t('market_desc') }}</p>
                      </div>
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </li>
        <li>
          <router-link to="about">
            <a @click="handleClose" class="navigation-item" href="javascript:;">{{ $t('about_us') }}</a>
          </router-link>
        </li>
      </ul>
      <a v-if="!isMobile" class="link-lang" @mouseenter="handlerHover('')" @click="changeLang">
        <i class="icon-lang"></i>
        <span class="txt">{{ country }}</span>
      </a>
      <div v-if="!isMobile" class="btn-wrapper" v-show="needLogin">
        <a @mouseenter="handlerHover('')" class="btn btn-login" href="https://dashboard.tikipay.co/user/login">{{ $t('log_in') }}</a>
        <a @click="jumpById('about', 'contact')" @mouseenter="handlerHover('')" class="btn btn-sign">{{ $t('sign_up') }}</a>
      </div>
      <div class="header-bottom" v-if="isMobile" v-show="needLogin">
        <div class="btn-wrapper">
          <a class="btn btn-login" href="https://dashboard.tikipay.co/user/login">{{ $t('log_in') }}</a>
          <a class="btn btn-sign" @click="jumpById('about', 'contact')">{{ $t('sign_up') }}</a>
        </div>
        <a class="link-lang" @click="changeLang">
          <i class="icon-lang"></i>
          <span class="txt">{{ country }}</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import i18n from '../i18n'
import utils from '@/assets/js/common';
import env from "../assets/js/env";
export default {
  props: {
    isTop: {
      type: Boolean,
    }
  },
  data() {
    return {
      clickJump: false,
      brand: env.brand,
      needLogin: false,
      isMobile: false,
      country: 'EN',
      lang: [
        { key: "en-US", label: "EN" },
        { key: "zh-CN", label: "CN" },
      ],
      isWhite: false,
      isHover: false,
      hoverVal: '',
      isShow: false,
      isShowProduct: false,
      isShowSolution: false,
      hoverHead: false,
    }
  },
  computed: {
    logo() {
      let brandLogo = env.brandLogoBlack
      if (this.isWhite) {
        brandLogo = env.brandLogoWhite
      }
      return { "--logo": brandLogo }
    }
  },
  watch: {
    "$route.path": {
      immediate: true,
      deep: true,
      handler() {
        this.isWhite = this.hasWhiteTheme()
        this.isShow = false;
        this.isShowProduct = false;
        this.isShowSolution = false;
        if (this.isMobile) {
          this.hoverVal = ''
          this.$emit('onHoverValue', '')
          this.$emit('onHandlerShow', this.isShow);
        }
        this.move();
      },
    },
  },
  mounted() {
    if (this._isMobile()) {
      this.isMobile = true
    } else {
      this.isMobile = false
    }
    if (env.brand === 'TiKiPay') {
      this.needLogin = true
    }
    this.isWhite = this.hasWhiteTheme()
    const currentLang = localStorage.getItem('lang')
    this.lang.forEach(element => {
      if (currentLang.indexOf(element.key) !== -1) {
        this.country = element.label
        i18n.locale = element.key
        this.$global.lang = element.label
        this.$store.commit('setCountry', element.label);
      }
    });
  },
  methods: {
    jumpById(path, id) {
      this.$router.push({
        path: `/${path}`,
        query: {
          id: id
        }
      })
    },
    handleMouseLeave() {
      this.hoverHead = false;
      if (!this.hoverHead) {
        this.handlerHover('')
      }
    },
    handleClose() {
      this.isWhite = this.hasWhiteTheme()
      this.isShow = false;
      this.isShowProduct = false;
      this.isShowSolution = false;
      if (this.isMobile) {
        this.hoverVal = ''
        this.$emit('onHoverValue', '')
        this.$emit('onHandlerShow', this.isShow);
      }
      this.move();
    },
    //禁止滚动
    stop(){
      var mo=function(e){e.preventDefault();};
      document.body.style.overflow='hidden';
      document.addEventListener("touchmove",mo,false);//禁止页面滑动
    },
    /***取消滑动限制***/
    move(){
      var mo=function(e){e.preventDefault();};
      document.body.style.overflow='';//出现滚动条
      document.removeEventListener("touchmove",mo,false);
    },
    handleClick() {
      this.isShow = !this.isShow;
      this.$emit('onHandlerShow', this.isShow);
      if (this.isShow) {
        this.stop();
      } else {
        this.move();
      }
    },
    handlerHover(value) {
      this.$emit('onHoverValue', value)
      if (value === 'product' || value === 'solution') {
        this.hoverVal = value
      } else {
        this.hoverVal = ''
      }
    },
    jump(path) {
      this.$router.push({
        path: `/${path}`
      })
      location.reload()
    },
    hasWhiteTheme() {
      let href = window.location.href
      return href.includes('about') || href.includes('marketing') || href.includes('solution');
    },
    getImageRealPath(img) {
      return utils.getImagePath(img)
    },
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    },
    changeLang() {
      let key, label;
      if (this.country === 'EN') {
        this.country = 'CN'
        key = 'zh-CN'
        label = 'CN'
      } else {
        this.country = 'EN'
        key = 'en-US'
        label = 'EN'
      }
      localStorage.setItem('lang', JSON.stringify(key))
      i18n.locale = key
      this.$global.lang = label; // 更新全局变量的值
      this.$store.commit('setCountry', label);
      if (this.isMobile) {
        this.handleClose();
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.header-drop-down {
  position: absolute;
  width: 100%;
  top: 98px;
  left: 0;
  background: #FAFAFA;
  opacity: 0;
  pointer-events: none;
  box-shadow: 0 4px 30px 2px rgba(0,0,0, 0.1);
  &.active {
    opacity: 1;
    pointer-events: auto;
    .head-drop-box {
      transform: translateX(0);
      opacity: 1;
    }

  }
  .content {
    width: 1180px;
    margin: 0 auto;
  }
  .head-drop-box {
    padding: 47px 54px 44px;
    display: flex;
    transition: all .3s ease;
    transform: translateX(16px);
    opacity: 0;
    .title {
      padding-top: 3px;
      margin-bottom: 0;
      font-size: 22px;

      font-weight: 400;
      color: #333333;
      height: 45px;
      display: flex;
      align-items: center;
      width: 120px;
      padding-right: 17px;
    }
    .list {
      display: flex;
      border-left: 2px solid #EEEFF1;
      padding-left: 40px;
      margin-bottom: 0;
      flex: 1 0 auto;
      li {
        display: flex;
        text-align: left;
        flex: 1;
        a {
          display: flex;
        }
        .icon-list {
          width: 40px;
          height: 40px;
          display: inline-block;
          margin-right: 20px;
          &.icon-list-sd {
            background-image: url('../assets/image/icon-sd.png');
          }
          &.icon-list-payment {
            background-image: url('../assets/image/icon-payment.png');
          }
          &.icon-list-solution {
            background-image: url('../assets/image/icon-solution.png');
          }
          &.icon-list-market {
            background-image: url('../assets/image/icon-market.png');
          }
        }
        h4 {
          font-size: 16px;

          font-weight: 500;
          color: #333333;
          line-height: 1.1;
          margin-bottom: 8px;
        }
        p {
          font-size: 14px;

          font-weight: 400;
          color: #6D798B;
          line-height: 1.1;
          margin-bottom: 0px;
        }
      }
    }
  }
}
.header-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  max-width: 1180px;
  margin: 0 auto;
  .logo-hd-box {
    text-align: left;
    a {
      display: flex;
      align-items: center;
      height: 100%;
    }
  }
  .right-box {
    text-align: right;
    display: flex;
    height: 100%;
    .link-lang {
      color: #000;
      display: inline-flex;
      align-items: center;
      &:hover {
        opacity: 0.75;
      }
    }
    .icon-lang {
      width: 16px;
      height: 16px;
      display: inline-block;
      vertical-align: top;
      margin-right: 4px;
      background-image: url('../assets/image/map-icon-black.png');
      background-size: 16px 16px;
    }
  }
  .btn-wrapper {
    display: flex;
    align-items: center;
    padding-left: 23px;
    .btn {
      height: 38px;
      border: 1px solid #1954EC;
      border-radius: 19px;
      font-size: 16px;

      font-weight: 400;
      color: #1954EC;
      padding: 0 21px;
      display: flex;
      align-items: center;
      margin-left: 18px;
      transition: all .3s ease;
      &:hover {
        background: #1954EC;
        color: #fff;
      }
    }
  }
  .ant-row-flex{
    width: 100%;
  }
  .logo-hd-tikipay {
    width: 166px;
    height: 33px;
    background-image: url('../assets/image/logo-black.png');
    background-size: 166px auto;
    background-repeat: no-repeat;
  }
  .logo-hd-faspal {
    width: 166px;
    height: 33px;
    background-image: url('../assets/image/faspal-logo-black.png');
    background-size: 166px auto;
    background-repeat: no-repeat;
  }
  .logo-hd-octopay {
    width: 93px;
    height: 66px;
    background-image: url('../assets/image/octo-logo3.png');
    background-size: 93px auto;
    background-repeat: no-repeat;
  }

  .navbar-nav {
    margin-bottom: 0;
  }

  .header-right {
    display: flex;
    align-items: center;
  }

  .navigation-list {
    display: flex;
    font-size: 16px;

    font-weight: 400;
    margin-bottom: 0;
    li {
      margin-right: 37px;
      display: flex;
      align-items: center;
    }
    a {
      color: #000000;
      font-weight: 400;

      &:hover {
        opacity: 0.75;
      }
    }
  }

  &.white {
    .logo-hd {
      background-image: url('../assets/image/logo-white.png');
    }
    .navigation-list {
      a {
        color: #fff;
      }
    }
    .right-box {
      .link-lang {
        color: #fff;
      }
      .icon-lang {
        background-image: url('../assets/image/map-icon-white.png');
      }
    }

    .btn-wrapper {

      .btn {
        border: 1px solid #fff;
        color: #fff;
        padding: 0 21px;
        display: flex;
        align-items: center;
        margin-left: 18px;
        transition: all .3s ease;
        &:hover {
          border-color: #1954EC;
          background: #1954EC;
          color: #fff;
        }
      }
    }

  }
}
.mobile {
  .header-main  {
    display: block;
    .right-box {
      display: none;
      padding: 0 px2rem(32);
      height: calc(100vh - .9rem);
      position: relative;
      border-top: px2rem(2) solid #F0F0F0;
      &.active {
        display: block;
      }
      .header-bottom {
        border-top: px2rem(2) solid #F0F0F0;
        position: fixed;
        bottom: 0;
        display: flex;
        justify-content: space-between;
        width: 100%;
        left: 0;
        right: 0;
        padding: 0 px2rem(32);
        z-index: 10;
        .btn-wrapper {
          padding-left: 0;
          .btn {
            margin-left: 0;
            color: #333333;
            padding: 0;
            border: none;
            margin-right: px2rem(40);
            font-size: px2rem(30);
          }
        }
      }
    }
    .row-2 {
      margin-top: px2rem(47);
    }
    .logo-hd-box {
      display: flex;
      justify-content: space-between;
      height: auto;
      align-items: center;
      padding: px2rem(20) px2rem(32);
      a {
        display: block;
        height: auto;
      }
      .icon {
        display: inline-block;
        width: px2rem(40);
        height: px2rem(40);
        background-image: url('../assets/image/icon-show-black.png');
        background-repeat: no-repeat;
        background-size: px2rem(40) auto;
        &.icon-close {
          background-image: url('../assets/image/icon-close.png');
        }
      }
    }
    .navigation-list {
      display: block;
      li {
        margin-right: 0;
        display: block;
        .navigation-item {
          border-bottom: px2rem(2) solid #F0F0F0;
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 1rem;
        }
        .navigation-item-ul {
          .icon {
            display: block;
            width: px2rem(40);
            height: px2rem(40);
            background-image: url('../assets/image/head-right.png');
            background-size: px2rem(40) px2rem(40);
            background-repeat: no-repeat;
            transition: all .3s ease;
          }
          &.active {
            .icon {
              transform: rotate(90deg);
            }
          }
        }
        a {
          display: block;
          width: 100%;
          text-align: left;
        }
        .header-drop-down {
          top: auto;
          box-shadow: none;
          background: none;
          position: relative;
          height: 0;
          overflow: hidden;
          &.active {
            position: relative;
            height: auto;
          }
          .content {
            width: auto;
          }
          .head-drop-box {
            padding: 0;
            padding-left: px2rem(64);
            display: block;
            transition: none;
            .list {
              padding-left: 0;
              border-left: 0;
              display: block;
              li {
                padding: px2rem(30) px2rem(30) px2rem(30) 0 ;
                border-bottom: px2rem(2) solid #F0F0F0;
                position: relative;
                &::before {
                  content: '';
                  position: absolute;
                  width: px2rem(8);
                  height: px2rem(8);
                  background: #AFB7C0;
                  left: px2rem(-49);
                  top: 50%;
                  transform: translateY(-50%);
                }
                &::after {
                  content: '';
                  position: absolute;
                  width: px2rem(40);
                  height: px2rem(40);
                  background-image: url('../assets/image/head-right.png');
                  background-size: px2rem(40) px2rem(40);
                  background-repeat: no-repeat;
                  right: 0;
                  top: 50%;
                  transform: translateY(-50%);
                }
              }
            }
          }
        }
      }
    }
    &.white {
      .logo-hd-box {
        .icon {
          background-image: url('../assets/image/icon-show-white.png');
        }
      }
    }
    .logo-hd {
      width: px2rem(225);
      height: px2rem(51);
      display: block;
      background-repeat: no-repeat;
      background-size: px2rem(225) auto;
    }
    .icon-lang {
      width: px2rem(28);
      height: px2rem(28);
      margin-right: px2rem(9);
      background-size:  px2rem(28) auto;
    }
    .link-lang {
      .txt {
        font-size: px2rem(30);
      }
    }
  }
}
</style>

<style lang="scss">
.mobile {
  .header-main .right-box {

    &.active {
      display: block;
    }
  }
}
</style>